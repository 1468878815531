import { RegisterOkResponse, RegisterParams, register } from "@/api/users/register"
import { useClient } from "@/providers/ClientProvider"
import { Button, Card, Flex, Heading, Link, Separator, Text, TextField } from "@radix-ui/themes"
import { useMutation } from "@tanstack/react-query"
import { Link as RouterLink } from "@tanstack/react-router"
import { Field, Form } from "houseform"
import { z } from "zod"

export const Register = () => {
	const client = useClient()
	const mutation = useMutation<RegisterOkResponse, unknown, RegisterParams>( {
		mutationFn: ( params ) => register( client, params ),
	} )
	const handleSubmit = async ( values: RegisterParams ) => {
		await mutation.mutateAsync( values )
	}

	return (
		<Flex
			className="h-full w-full bg-cover bg-right bg-no-repeat"
			justify="center"
			align="center"
			style={{ background: "url(/assets/imgs/bg-abstract.png)" }}
		>
			<Card size="4" className="w-[400px]">
				<Flex gap="5" direction="column">
					<Heading size="5" weight="medium">
						Register
					</Heading>
					<Separator size="4" />
					<Form<RegisterParams> onSubmit={handleSubmit}>
						{( { submit } ) => {
							return (
								<Flex asChild gap="5" direction="column">
									<form
										onSubmit={( e ) => {
											e.preventDefault()
											submit()
										}}
									>
										<Field
											name="first_name"
											onBlurValidate={z
												.string()
												.nonempty(
													"First Name should be atleast contain one character",
												)}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="First Name..."
														></TextField.Input>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Field
											name="last_name"
											onBlurValidate={z
												.string()
												.nonempty(
													"Last Name should be atleast contain one character",
												)}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="Last Name..."
														></TextField.Input>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Field
											name="email"
											onBlurValidate={z
												.string()
												.email( "This must be an email" )}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="Email..."
														></TextField.Input>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Field
											name="password"
											onBlurValidate={z
												.string()
												.min(
													8,
													"Password must contain atleast 8 characters",
												)}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															type="password"
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="Password..."
														></TextField.Input>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Button type="submit" variant="solid">
											Register
										</Button>
										<Link className="self-center">
											<RouterLink to="/user/login">Login instead?</RouterLink>
										</Link>
									</form>
								</Flex>
							)
						}}
					</Form>
				</Flex>
			</Card>
		</Flex>
	)
}
