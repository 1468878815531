import { c } from "./contract-instance"
import { invoices } from "./invoices"
import { masterData } from "./master-data"
import { providers } from "./providers"

export const contract = c.router(
	{
		invoices,
		masterData,
		providers,
	},
	{
		pathPrefix: "/v1",
	},
)
