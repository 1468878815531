import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"
import { TeamCollection, TeamMember } from "./list-team-resources"

export const listTeams = (
	ky: typeof Ky,
	params: { page_no?: number; page_size?: number } = { page_no: 1, page_size: 150 },
) => {
	const headers = createAuthHeader()
	return ky
		.get( `${config.api}/user/v1/teams`, { headers, searchParams: params } )
		.json<GetTeamsOkResponse>()
}

export type GetTeamsOkResponse = {
	status: "success"
	details: {
		teams: Team[]
		total_pages: number
		total_records: number
	}
	message: ""
}

export type Team = {
	team_name: string
	team_id: number
	team_collections: TeamCollection[]
	admin_email: string
	members: TeamMember[]
}
