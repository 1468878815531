import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const deleteTeam = ( ky: typeof Ky, { team_id }: DeleteTeamParams ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/user/v1/teams/${team_id}`, { headers } )
		.json<DeleteTeamOkResponse>()
}

export type DeleteTeamParams = {
	team_id: number
}

export type DeleteTeamOkResponse = unknown
