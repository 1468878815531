import { z } from "zod"
import { c } from "./contract-instance"
import { AttachFileSchema, FileSchema } from "./files"
import { DataMappingSchema } from "./providers"

export const InvoiceStatusEnumSchema = z
	.enum( [
		"DRAFT",
		"PROCESSING",
		"PROCESSING_FAILED",
		"VERIFICATION_PENDING",
		"MERGING",
		"MERGED",
		"MERGING_FAILED",
	] )
	.openapi( {
		title: "Invoice Status",
	} )

export const CreateInvoiceStatusEnum = InvoiceStatusEnumSchema.exclude( [
	"MERGED",
	"MERGING",
	"MERGING_FAILED",
	"PROCESSING_FAILED",
	"VERIFICATION_PENDING",
] ).default( "DRAFT" )

export const UserSchema = z.object( {
	user_id: z.number(),
	user_email: z.string().email(),
} )

export const ExtractionSetting = DataMappingSchema.pick( { id: true } ).extend( {
	should_extract: z.boolean(),
} )

export const ExtractionStatusEnumSchema = z.enum( [ "Success", "Error", "Processing" ] )

export const ExtractionFile = FileSchema.extend( {
	status: ExtractionStatusEnumSchema,
} )

export const ExtractedData = z.object( {
	accuracy: z.number().min( 0 ).max( 100 ).nullable(),
	field_name: z.string(),
	value: z.coerce.string().nullable(),
	index: z.number().int(),
} )

export type ExtractedDataType = z.infer<typeof ExtractedData>

export const ValidatedData = ExtractedData

export type ValidatedDataType = z.infer<typeof ExtractedData>

export const MappingDataSchema = z.object( {
	active: z.boolean(),
	data_type: z.enum( [ "string", "date", "float" ] ),
	invoice_field_name: z.string(),
	master_data_field_name: z.string(),
} )

export type MappingDataType = z.infer<typeof MappingDataSchema>

export const InvoiceExtractedData = z.object( {
	file_url: z.string(),
	extracted_data: ExtractedData.array(),
	original_data: z.record( z.string().nullable() ),
	invoice_processing_id: z.number(),
	is_validated: z.boolean().catch( false ),
} )

export const InvoiceValidatedData = z.object( {
	file_url: z.string(),
	extracted_data: ExtractedData.array(),
	invoice_processing_id: z.number(),
	is_validated: z.boolean().catch( false ),
} )

export type InvoiceExtractedDataType = z.infer<typeof InvoiceExtractedData>

// Create ValidatedDataSchema by omitting 'original_data' from InvoiceExtractedData
const ValidatedDataSchema = InvoiceValidatedData

export type ValidatedData = z.infer<typeof ValidatedDataSchema>

const GetBulkInvoiceExtractedData = InvoiceExtractedData.array().array()
const GetBulkValidatedData = ValidatedDataSchema.array().array()

export type GetBulkInvoiceExtractedDataType = z.infer<typeof GetBulkInvoiceExtractedData>
export type GetBulkValidatedDataType = z.infer<typeof GetBulkValidatedData>

export const GetBulkInvoiceOkResponse = z.object( {
	id: z.number(),
	provider: z.object( {
		id: z.number(),
		name: z.string(),
		icon: z.string(),
		mapping_data: MappingDataSchema.array(),
		mapping_schema: MappingDataSchema.array().catch( [] ),
		supported_formats: z.array( z.string() ),
		created_by: z.string(),
		status: z.string(),
	} ),
	uploaded_files: FileSchema.pick( { url: true, size: true } ).array().catch( [] ),
	error_details: z.string().nullable(),
	extracted_data: z.union( [ GetBulkInvoiceExtractedData, z.object( {} ).strict() ] ),
	validated_data: GetBulkValidatedData.catch( [] ),
	status: InvoiceStatusEnumSchema,
	created_by: UserSchema,
	merge_timestamp: z.null(),
	validation_progress: z.number().min( 0 ).max( 100 ).catch( 0 ),
	created_at: z.string().datetime(),
	validated_count: z.number().min( 0 ).int(),
	total_count: z.number().min( 0 ).int(),
} )

export const ListBulkInvoicesOkResponse = z.object( {
	count: z.number(),
	next: z.string().nullable(),
	previous: z.string().nullable(),
	results: GetBulkInvoiceOkResponse.pick( {
		id: true,
		status: true,
		created_by: true,
		created_at: true,
		validation_progress: true,
		validated_count: true,
		total_count: true,
		error_details: true,
		uploaded_files: true,
		provider: true,
	} )
		.strict()
		.array(),
} )

export const CreateInvoiceSchema = z.object( {
	provider: z.string().uuid(),
	files: AttachFileSchema.array(),
} )

const files = c.router(
	{
		listInvoices: {
			method: "GET",
			responses: {
				200: ListBulkInvoicesOkResponse,
			},
			query: z.object( {
				page: z.number().optional(),
				page_size: z.number().optional(),
				status: InvoiceStatusEnumSchema.optional(),
				status__in: z.string().optional(),
			} ),
			path: "/",
		},
		getInvoice: {
			method: "GET",
			pathParams: z.object( { invoice_id: z.string() } ),
			responses: {
				200: GetBulkInvoiceOkResponse,
			},
			path: "/:invoice_id/",
		},
		updateInvoice: {
			method: "PATCH",
			path: "/:invoice_id/",
			contentType: "multipart/form-data",
			body: c.type<{
				validated_data?: GetBulkInvoiceExtractedDataType
				status?: z.infer<typeof CreateInvoiceStatusEnum>
				validated_count?: number
			}>(),
			responses: {
				200: z.object( {
					message: z.string(),
					details: z
						.object( {
							status: InvoiceStatusEnumSchema,
						} )
						.passthrough(),
				} ),
			},
		},
	},
	{
		pathPrefix: "/files",
	},
)

export const CreateInvoiceBody = z.object( {
	provider: z.coerce.number(),
	uploaded_files: z.array( z.instanceof( File ) ).nonempty(),
	status: CreateInvoiceStatusEnum,
} )

export type CreateInvoiceBodyType = z.infer<typeof CreateInvoiceBody>

export const CreateInvoiceCreatedResponse = z.object( {
	message: z.string(),
	details: z.object( {
		id: z.number(),
		provider: z.number(),
		uploaded_files: z.array( z.null() ),
		extracted_data: z.object( {} ),
		status: InvoiceStatusEnumSchema,
		merge_timestamp: z.string().nullable(),
		created_by: UserSchema,
	} ),
} )

export const ProjectsResultsSchema = z.object( {
	created_at: z.string(),
	id: z.number(),
	project_name: z.string(),
	project_number: z.string(),
	updated_at: z.string(),
} )

export const ListProjectsResponse = z.object( {
	count: z.number(),
	next: z.number().nullable(),
	previous: z.number().nullable(),
	results: z.array( ProjectsResultsSchema ),
} )

export const invoices = c.router(
	{
		createInvoice: {
			method: "POST",
			path: "/upload/",
			contentType: "multipart/form-data",
			body: c.type<{
				provider: number
				uploaded_files: File
				status?: z.infer<typeof CreateInvoiceStatusEnum>
			}>(),
			responses: {
				200: CreateInvoiceCreatedResponse,
			},
		},
		completeValidation: {
			method: "GET",
			path: "/merge/:invoice_id/",
			responses: {
				200: z.any(),
			},
		},
		listProjects: {
			method: "GET",
			path: "/projects",
			responses: {
				200: ListProjectsResponse,
			},
		},
		files,
	},
	{
		pathPrefix: "/invoice",
	},
)
