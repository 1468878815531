import Ky from "ky"
import config from "@/config"

export const googleLogin = ( ky: typeof Ky, params: GoogleLoginParams ) => {
	return ky
		.get( `${config.api}/user/google-auth/callback/`, {
			searchParams: { ...params, redirect_uri: config.google.redirectUri as string },
		} )
		.json<GoogleLoginOkResponse>()
}

export type GoogleLoginParams = {
	code: string
}

export type GoogleLoginOkResponse = {
	token: string
}
