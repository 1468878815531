import { DotsLoader } from "@/components/DotsLoader/DotsLoader"
import { GenericError } from "@/components/GenericError"
import config from "@/config"
import { ClientProvider } from "@/providers/ClientProvider"
import { QueryProvider } from "@/providers/QueryProvider"
import { router } from "@/routes/router"
import { Theme } from "@radix-ui/themes"
import { RouterProvider } from "@tanstack/react-router"
import posthog from "posthog-js"
import { Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"

if ( config.feature_flags.posthog && typeof config.posthog_api_key === "string" ) {
	posthog.init( config.posthog_api_key, {
		api_host: "https://us.i.posthog.com",
	} )
}

export const App = () => {
	return (
		<Theme className="h-full w-full max-w-full overflow-y-auto overflow-x-hidden">
			<ErrorBoundary
				fallbackRender={( { error, resetErrorBoundary } ) => (
					<GenericError
						message={error?.message}
						resetErrorBoundary={resetErrorBoundary}
					/>
				)}
			>
				<Suspense fallback={<DotsLoader />}>
					<QueryProvider>
						<ClientProvider>
							<RouterProvider router={router} />
						</ClientProvider>
					</QueryProvider>
				</Suspense>
			</ErrorBoundary>
		</Theme>
	)
}
