import { DotsLoader } from "@/components/DotsLoader/DotsLoader"
import { client } from "@/providers/ClientProvider"
import { pusher } from "@/providers/PusherProvider"
import { queryClient } from "@/providers/QueryProvider"
import { ErrorComponent, createRouter } from "@tanstack/react-router"
import {
	authenticatedRoute,
	casestudyRoute,
	chartGeneratorRoute,
	chartInterpreterRoute,
	chatsRoute,
	compareMySiteRoute,
	contractIntelligenceRoute,
	customerAnalyticsRoute,
	customerServiceRoute,
	dbGptRoute,
	docsComparisonRoute,
	documentGeneratorRoute,
	documentProcessRoute,
	emailsRoute,
	employeeOnboarding,
	energyManagementRoute,
	floorGeneratorRoute,
	flowchartRoute,
	forecastAgentRoute,
	generateInvoiceRoute,
	groqSummarisationAgentRoute,
	leaseAgreementRoute,
	legalJargonRoute,
	marketIntelligenceRoute,
	marketingRoute,
	meetingSchedulingRoute,
	procoreAutomationRoute,
	projectManagementRoute,
	salesPitchGeneratorRoute,
	scribeAiRoute,
	securityChecksCloudAppsRoute,
	speechToTextRoute,
	summarisationAgentRoute,
	textToSpeechRoute,
	trademarkAgentApplicationRoute,
	trademarkAgentCreateApplicationRoute,
	trademarkAgentRoute,
} from "./authenticated-route"
import {
	emailLoginRoute,
	googleLoginCallback,
	loginRoute,
	nonAuthenticatedRoute,
	registerRoute,
} from "./non-authenticated-route"
import {
	createInvoiceRoute,
	executiveReportRoute,
	invoiceSettingsRoute,
	listDraftInvoicesRoute,
	listInvoicesRoute,
	masterDataRoute,
	performanceServiceDash,
	reviewDataRoute,
	validationQueueRoute,
} from "./performance-service-route"
import { rootRoute } from "./root-route"
import {
	dataCollectionsRoute,
	guardrailsRoute,
	inviteUsersRoute,
	modelConfigurationRoute,
	settingsRoute,
	teamManagementRoute,
	userSettingsRoute,
	widgetsRoute,
} from "./settings-routes"
import { swaggerRoute } from "./swagger-route"

const routeTree = rootRoute.addChildren( [
	authenticatedRoute.addChildren( [
		settingsRoute.addChildren( [
			teamManagementRoute,
			dataCollectionsRoute,
			inviteUsersRoute,
			userSettingsRoute,
			widgetsRoute,
			guardrailsRoute,
			modelConfigurationRoute,
		] ),
		chatsRoute,
		compareMySiteRoute,
		casestudyRoute,
		chartGeneratorRoute,
		chartInterpreterRoute,
		contractIntelligenceRoute,
		customerAnalyticsRoute,
		dbGptRoute,
		docsComparisonRoute,
		documentGeneratorRoute,
		documentProcessRoute,
		emailsRoute,
		floorGeneratorRoute,
		flowchartRoute,
		forecastAgentRoute,
		generateInvoiceRoute,
		groqSummarisationAgentRoute,
		leaseAgreementRoute,
		legalJargonRoute,
		marketIntelligenceRoute,
		marketingRoute,
		meetingSchedulingRoute,
		projectManagementRoute,
		salesPitchGeneratorRoute,
		scribeAiRoute,
		securityChecksCloudAppsRoute,
		speechToTextRoute,
		summarisationAgentRoute,
		textToSpeechRoute,
		customerServiceRoute,
		energyManagementRoute,
		employeeOnboarding,
		procoreAutomationRoute,
		trademarkAgentRoute.addChildren( [
			trademarkAgentApplicationRoute.addChildren( [ trademarkAgentCreateApplicationRoute ] ),
		] ),
		performanceServiceDash.addChildren( [
			listInvoicesRoute,
			listDraftInvoicesRoute,
			createInvoiceRoute,
			invoiceSettingsRoute,
			validationQueueRoute,
			reviewDataRoute,
			masterDataRoute,
			executiveReportRoute,
		] ),
	] ),

	nonAuthenticatedRoute.addChildren( [
		loginRoute,
		emailLoginRoute,
		googleLoginCallback,
		registerRoute,
	] ),

	swaggerRoute,
] )

export const router = createRouter( {
	routeTree,
	defaultPendingComponent: DotsLoader,
	defaultErrorComponent: ( { error } ) => <ErrorComponent error={error} />,
	defaultPendingMs: 0,
	context: {
		queryClient,
		client,
		pusher,
	},
} )

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router
	}
}
