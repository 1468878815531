import { Toaster } from "@/components/Toast/Toaster"
import { QueryClient } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
import { KyInstance } from "ky"
import Pusher from "pusher-js"
import React from "react"

type RouterContext = {
	client: KyInstance
	queryClient: QueryClient
	pusher: Pusher
}

export const rootRoute = createRootRouteWithContext<RouterContext>()( {
	beforeLoad: () => {
		return {
			title: "",
		}
	},
	component: () => (
		<React.Fragment>
			<Outlet />
			<Toaster />
		</React.Fragment>
	),
} )
