import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const addTeamMembers = ( ky: typeof Ky, { members_ids, team_id }: AddTeamMembersParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/user/v1/teams/${team_id}/add_members`, {
			json: { members_ids },
			headers,
		} )
		.json<AddTeamMembersOkResponse>()
}

export type AddTeamMembersParams = {
	team_id: number
	members_ids: number[]
}

export type AddTeamMembersOkResponse = number
