import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"
import { CreateGuardrailResponse, GuardrailParams } from "./create-guardrail"

export const updateGuardrail = ( ky: typeof Ky, { payload, guardrailId }: UpdateGuardrailParams ) => {
	const headers = createAuthHeader()

	return ky
		.patch( `${config.api}/v1/gaurdrail/${guardrailId}`, {
			json: payload,
			headers,
		} )
		.json<CreateGuardrailResponse>()
}

export interface UpdateGuardrailParams {
	guardrailId: string
	payload: GuardrailParams
}
export type UpdateGuardrailId = {
	guardrail_id: string
}
