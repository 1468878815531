import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const deleteFile = ( ky: typeof Ky, params: DeleteFileParams ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/v1/file/delete`, { headers, searchParams: params } )
		.json<DeleteFileOkResponse>()
}

export type DeleteFileParams = {
	file_path: string
}

export type DeleteFileOkResponse = unknown
