import config from "@/config"
import GoogleButton from "react-google-button"

export const GoogleLogin = () => {
	const googleUrl = new URL( "https://accounts.google.com/o/oauth2/auth" )
	googleUrl.searchParams.set( "client_id", config.google.clientId! )
	googleUrl.searchParams.set( "access_type", "offline" )
	googleUrl.searchParams.set( "redirect_uri", config.google.redirectUri! )
	googleUrl.searchParams.set( "response_type", "code" )
	googleUrl.searchParams.set(
		"scope",
		[
			"https://www.googleapis.com/auth/userinfo.email",
			"https://www.googleapis.com/auth/userinfo.profile",
			"https://www.googleapis.com/auth/drive",
			"openid",
		].join( " " ),
	)

	return (
		<a href={googleUrl.toString()} className="w-full">
			<GoogleButton style={{ width: "100%" }} />
		</a>
	)
}

export const GoogleLoginCallback = () => {
	return null
}
