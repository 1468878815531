import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const getSchedulingOptions = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky
		.get( `${config.api}/v1/collections/scheduling_options`, { headers } )
		.json<SettingOption[]>()
}

export type SchedulingOptionsResponse = {
	scheduling_options: SettingOption[]
}

export interface SettingOption {
	display_name: string
	value: string
	description: string
}
