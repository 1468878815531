import config from "@/config"
import { getPersistedAuth } from "@/utils/auth"
import Pusher, { Channel } from "pusher-js"
import React from "react"

export type ToastVariant = "destructive" | "success" | "warning" | "info" | "default"

export type ToastData = {
	title: string
	variant: ToastVariant
	description?: string
}

export type PusherPayload<T = null> = {
	toast: ToastData | null
	data: T
}

export const pusher = new Pusher( config.pusher.appKey, {
	wsHost: config.pusher.wsHost,
	wsPort: config.pusher.wsPort,
	httpPort: config.pusher.httpPort,
	forceTLS: true,
	disableStats: true,
	enabledTransports: [ "ws", "wss" ],
	httpHost: config.pusher.httpHost,
	userAuthentication: {
		endpoint: `${config.api}/v1/pusher/notification/authn`,
		transport: "ajax",
		headersProvider() {
			const token = getPersistedAuth()
			return {
				Authorization: `Token ${token}`,
			}
		},
	},
	channelAuthorization: {
		endpoint: `${config.api}/v1/pusher/notification/authz`,
		transport: "ajax",
		headersProvider() {
			const token = getPersistedAuth()
			return {
				Authorization: `Token ${token}`,
			}
		},
	},
} )

const PusherContext = React.createContext<PusherContextValue>( null! )

export const PusherProvider: React.FC<PusherProviderProps> = ( { children } ) => {
	const [ channels, setChannels ] = React.useState( () => ( {} ) as Record<string, Channel> )

	return (
		<PusherContext.Provider value={{ pusher, channels, setChannels }}>
			{children}
		</PusherContext.Provider>
	)
}

export const usePusher = () => React.useContext( PusherContext )

export type PusherProviderProps = React.PropsWithChildren

export type PusherContextValue = {
	pusher: Pusher
	channels: Record<string, Channel>
	setChannels: React.Dispatch<React.SetStateAction<Record<string, Channel>>>
}
