import { LoginOkResponse, LoginParams, login } from "@/api/users/login"
import { DotsLoader } from "@/components/DotsLoader/DotsLoader"
import { useToast } from "@/components/Toast/use-toast"
import { useClient } from "@/providers/ClientProvider"
import { persistAuth } from "@/utils/auth"
import {
	Button,
	Card,
	Flex,
	Heading,
	IconButton,
	Separator,
	Text,
	TextField,
} from "@radix-ui/themes"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { Field, Form, FormProps } from "houseform"
import { HTTPError } from "ky"
import { EyeIcon, EyeOff } from "lucide-react"
import { useState } from "react"
import { z } from "zod"

export const EmailLogin = () => {
	const [ isShowPassword, setIsPassword ] = useState<boolean>()
	const client = useClient()
	const navigate = useNavigate()
	const { toast } = useToast()
	const emailLoginMutation = useMutation<LoginOkResponse, unknown, LoginParams>( {
		mutationFn: ( params ) => login( client, params ),
		onSuccess: ( data ) => {
			persistAuth( data.token )
			navigate( {
				to: "/",
			} )
		},
	} )

	const handleSubmit: FormProps<LoginParams>["onSubmit"] = async ( values, _ ) => {
		emailLoginMutation.mutateAsync( values ).catch( async ( error ) => {
			console.dir( error )
			if ( error instanceof HTTPError ) {
				if ( error.response.status === 401 ) {
					const data = ( await error.response.json() ) as { error?: string }

					if ( typeof data.error === "string" ) {
						toast( {
							title: "Error while Logging In",
							description: data.error,
							variant: "warning",
						} )
					}
				}

				if ( error.response.status === 402 ) {
					const data = ( await error.response.json() ) as { message?: string }

					if ( typeof data.message === "string" ) {
						toast( {
							title: "Error while Logging In",
							description: data.message,
							variant: "warning",
						} )
					}
				}
			}
		} )
	}

	const { isPending, isSuccess } = emailLoginMutation

	return (
		<Flex
			className="h-full w-full bg-cover bg-right bg-no-repeat"
			justify="center"
			align="center"
			style={{ background: "url(/assets/imgs/bg-abstract.png)" }}
		>
			<Card size="4" className="w-[400px]">
				<Flex gap="5" direction="column">
					<Heading size="5" weight="medium" className="text-center">
						Login
					</Heading>
					<Separator size="4" />
					<Form<LoginParams> onSubmit={handleSubmit}>
						{( { submit } ) => {
							return (
								<Flex asChild gap="5" direction="column">
									<form
										onSubmit={( e ) => {
											e.preventDefault()
											submit()
										}}
									>
										<Field
											name="reg_email"
											onChangeValidate={z
												.string()
												.email( "This must be an email" )}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="Email..."
														></TextField.Input>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Field
											name="password"
											onChangeValidate={z
												.string()
												.min(
													8,
													"Password must contain atleast 8 characters",
												)}
										>
											{( { value, setValue, onBlur, errors } ) => (
												<Flex direction="column" gap="1">
													<TextField.Root>
														<TextField.Input
															value={value}
															type={
																isShowPassword ? "text" : "password"
															}
															onChange={( e ) =>
																setValue( e.target.value )
															}
															onBlur={onBlur}
															placeholder="Password..."
														></TextField.Input>
														<TextField.Slot>
															<IconButton
																type="button"
																variant="ghost"
																color="gray"
																onClick={() =>
																	setIsPassword( !isShowPassword )
																}
															>
																{isShowPassword ? (
																	<EyeIcon
																		height="16"
																		width="16"
																	/>
																) : (
																	<EyeOff
																		height="16"
																		width="16"
																	/>
																)}
															</IconButton>
														</TextField.Slot>
													</TextField.Root>
													{errors.map( ( error, idx ) => (
														<Text
															key={`error-${idx}`}
															color="crimson"
															size="1"
															weight="medium"
														>
															{error}
														</Text>
													) )}
												</Flex>
											)}
										</Field>
										<Button
											type="submit"
											variant="solid"
											className={`${
												isPending || isSuccess
													? "bg-gray-5"
													: "bg-accent-9 hover:bg-accent-10"
											} `}
											disabled={isPending || isSuccess}
										>
											{isPending || isSuccess ? <DotsLoader /> : "Login"}
										</Button>
									</form>
								</Flex>
							)
						}}
					</Form>
				</Flex>
			</Card>
		</Flex>
	)
}
