const columnHeaderStyles = "text-xs text-[#64748B] font-medium"

const BULK_VENDOR = "Bulk Vendor Update"
const INDIVIDUAL_VENDOR = "Individual Vendor Update"
const VENDORS_MAPPING = "Vendors Mapping"

const dummyVendorMappingList = [
	{
		id: 1,
		created_at: "15/11/2023 09:23",
		vendor_id: "V00123456",
		vendor_name_in_procore: "Acme Construction Supplies",
		vendor_name_in_csv: "ACME CONST. SUPP.",
	},
	{
		id: 2,
		created_at: "16/11/2023 14:05",
		vendor_id: "V00789012",
		vendor_name_in_procore: "BuildRight Materials Inc.",
		vendor_name_in_csv: "BldRght Mat.",
	},
	{
		id: 3,
		created_at: "17/11/2023 11:30",
		vendor_id: "V00345678",
		vendor_name_in_procore: "Global Steel Fabricators",
		vendor_name_in_csv: "Glbl Stl Fab",
	},
	{
		id: 4,
		created_at: "18/11/2023 08:45",
		vendor_id: "V00901234",
		vendor_name_in_procore: "EcoTech Roofing Solutions",
		vendor_name_in_csv: "EcoTech Roof",
	},
	{
		id: 5,
		created_at: "19/11/2023 16:12",
		vendor_id: "V00567890",
		vendor_name_in_procore: "Premier Electrical Systems",
		vendor_name_in_csv: "Prem. Elec. Sys.",
	},
	{
		id: 6,
		created_at: "20/11/2023 10:55",
		vendor_id: "V01123456",
		vendor_name_in_procore: "Concrete Masters Ltd.",
		vendor_name_in_csv: "Conc. Mstr.",
	},
	{
		id: 7,
		created_at: "21/11/2023 13:40",
		vendor_id: "V01789012",
		vendor_name_in_procore: "SafetyFirst Equipment Co.",
		vendor_name_in_csv: "SFTY1ST Equip",
	},
	{
		id: 8,
		created_at: "22/11/2023 09:18",
		vendor_id: "V01345678",
		vendor_name_in_procore: "Innovative Plumbing Solutions",
		vendor_name_in_csv: "Innov. Plumb.",
	},
	{
		id: 9,
		created_at: "23/11/2023 15:33",
		vendor_id: "V01901234",
		vendor_name_in_procore: "HeavyLift Crane Services",
		vendor_name_in_csv: "HvyLft Crane",
	},
	{
		id: 10,
		created_at: "24/11/2023 12:07",
		vendor_id: "V01567890",
		vendor_name_in_procore: "GreenScape Landscaping",
		vendor_name_in_csv: "GrnScpe Lndscpe",
	},
]

export {
	columnHeaderStyles,
	BULK_VENDOR,
	INDIVIDUAL_VENDOR,
	VENDORS_MAPPING,
	dummyVendorMappingList,
}
