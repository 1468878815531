import { Button } from "@radix-ui/themes"
import React from "react"

type GenericErrorProps = {
	statusCode?: number | string
	message?: string
	resetErrorBoundary: () => void
	customResolutionMessage?: React.ReactNode
}

export const GenericError: React.FC<GenericErrorProps> = ( {
	resetErrorBoundary,
	message,
	statusCode,
	customResolutionMessage,
} ) => {
	return (
		<div className="flex h-full w-full flex-col items-center justify-center  ">
			<div className="rounded-2xl border-daygrey-300 flex flex-col  items-center gap-3 border border-dashed p-8">
				<div className="flex max-w-xs flex-col items-center">
					<span className="text-xl font-semibold text-gray-12">
						Something went wrong!
					</span>
					<span className="text-center text-sm text-gray-11">{message}</span>
				</div>
				<div className="flex w-full flex-col items-center gap-2">
					{[ 400, 404 ].includes( statusCode as number ) ? null : (
						<Button className="w-full" onClick={resetErrorBoundary}>
							Retry
						</Button>
					)}
					{customResolutionMessage ? (
						customResolutionMessage
					) : (
						<Button
							color="indigo"
							className="w-full"
							onClick={() => {
								window.location.href = `/`
							}}
						>
							Go to Homepage
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}
