import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const removeTeamMembers = (
	ky: typeof Ky,
	{ members_ids, team_id }: RemoveTeamMembersParams,
) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/user/v1/teams/${team_id}/remove_members`, {
			json: { members_ids },
			headers,
		} )
		.json<RemoveTeamMembersOkResponse>()
}

export type RemoveTeamMembersParams = {
	team_id: number
	members_ids: number[]
}

export type RemoveTeamMembersOkResponse = number
