import { googleLogin } from "@/api/users/google-login"
import { EmailLogin } from "@/features/login/EmailLogin"
import { Login } from "@/features/login/Login"
import { Register } from "@/features/register/Register"
import { persistAuth } from "@/utils/auth"
import { createRoute, redirect } from "@tanstack/react-router"
import { HTTPError } from "ky"
import { z } from "zod"
import { rootRoute } from "./root-route"

export const nonAuthenticatedRoute = createRoute( {
	getParentRoute: () => rootRoute,
	path: "/user",
} )

export const loginRoute = createRoute( {
	getParentRoute: () => nonAuthenticatedRoute,
	path: "login",
	component: Login,
	validateSearch: z.object( {
		redirect: z.string().optional(),
		error: z.string().optional(),
	} ),
} )

export const emailLoginRoute = createRoute( {
	getParentRoute: () => nonAuthenticatedRoute,
	path: "email-login",
	component: EmailLogin,
} )

export const googleCallbackSearchSchema = z.object( {
	code: z.string(),
	scope: z.string(),
	authuser: z.string(),
	prompt: z.string(),
	error: z.string().optional(),
} )

export const googleLoginCallback = createRoute( {
	getParentRoute: () => nonAuthenticatedRoute,
	path: "google-auth/callback/",
	beforeLoad: async ( { context: { queryClient, client } } ) => {
		const code = new URLSearchParams( window.location.search ).get( "code" )!
		const data = await queryClient.ensureQueryData( {
			queryKey: [ "google-callback" ],
			queryFn: () =>
				googleLogin( client, { code } ).catch( async ( error ) => {
					if (
						error instanceof HTTPError &&
						[ 401, 400, 402 ].includes( error.response.status )
					) {
						const data = ( await error.response.json() ) as { message: string }

						throw redirect( { to: "/user/login", search: { error: data.message } } )
					}

					if ( error instanceof HTTPError && error.response.status === 500 ) {
						throw redirect( { to: "/user/login", search: { error: "unknown" } } )
					}
				} ),
		} )

		if ( data ) {
			persistAuth( data.token )

			throw redirect( { to: "/" } )
		}

		return null
	},
	validateSearch: googleCallbackSearchSchema,
} )

export const registerRoute = createRoute( {
	getParentRoute: () => nonAuthenticatedRoute,
	path: "register",
	component: Register,
} )
