import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const addTeamCollections = (
	ky: typeof Ky,
	{ collection_ids, team_id }: AddTeamCollectionsParams,
) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/user/v1/teams/${team_id}/add_collections`, {
			json: { collection_ids },
			headers,
		} )
		.json<AddTeamCollectionsOkResponse>()
}

export type AddTeamCollectionsParams = {
	team_id: number
	collection_ids: number[]
}

export type AddTeamCollectionsOkResponse = number
