import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const removeTeamCollections = (
	ky: typeof Ky,
	{ collection_ids, team_id }: RemoveTeamCollectionsParams,
) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/user/v1/teams/${team_id}/remove_collections`, {
			json: { collection_ids },
			headers,
		} )
		.json<RemoveTeamCollectionsOkResponse>()
}

export type RemoveTeamCollectionsParams = {
	team_id: number
	collection_ids: number[]
}

export type RemoveTeamCollectionsOkResponse = number
