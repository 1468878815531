import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const listModelProviders = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky
		.get( `${config.api}/v1/provider_model_list`, { headers } )
		.json<ListModelProvidersOkResponse>()
}

export type ListModelProvidersOkResponse = {
	status: string
	detail: Detail
}

export type Detail = {
	providers: {
		type: string
		label: string
		models: { name: string }[]
	}[]
}
