import config from "@/config"
import Ky from "ky"
import { createAuthHeader } from "@/utils/auth"

export const listTeamResources = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky
		.get( `${config.api}/user/v1/teams/list_users_and_collection`, { headers } )
		.json<GetTeamsOkResponse>()
}

export type TeamMember = {
	user_id: number
	user_name: string
	user_email: string
}

export type TeamCollection = {
	collection_id: number
	collection_name: string
}

export type GetTeamsOkResponse = {
	status: "success"
	details: {
		members: TeamMember[]
		collections: TeamCollection[]
	}
}
