import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const fetchMe = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky.get( `${config.api}/user/me`, { headers } ).json<FetchMeOkResponse>()
}

export type FetchMeParams = {
	code: string
}

export type EnabledFeature = Record<string, boolean>

export type FetchMeOkResponse = {
	message: {
		id: number
		first_name: string
		image_url: string
		register_email: string
		second_name: string
		role: "MEMBER" | "ADMIN"
		organisation_logo_url: string
		login_type: string
		organisation: number
		organisation_name: string

		enabled_features: EnabledFeature
	}
}
