import config from "@/config"
import Ky from "ky"

export const register = ( ky: typeof Ky, params: RegisterParams ) => {
	return ky.post( `${config.api}/user/create_user`, { json: params } ).json<RegisterOkResponse>()
}

export type RegisterParams = {
	first_name: string
	second_name: string
	reg_email: string
	password: string
}

export type RegisterOkResponse = {
	mesg: "user registered successfully"
}
