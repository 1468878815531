import { getRolesList } from "@/api/employee_onboarding/get_roles_list"
import { fetchMe } from "@/api/users/fetch-me"
import { toast } from "@/components/Toast/use-toast"
import config from "@/config"
import {
	BULK_VENDOR,
	INDIVIDUAL_VENDOR,
	VENDORS_MAPPING,
} from "@/features/procore_automation/constants"
import { PusherPayload } from "@/providers/PusherProvider"
import { clearAuth, getPersistedAuth } from "@/utils/auth"
import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router"
import { Channel } from "pusher-js"
import { z } from "zod"
import { rootRoute } from "./root-route"

export const featureSchema = z.enum( [
	"chats",
	"compare_my_site",
	"emails",
	"marketing",
	"sales_pitch_generator",
	"legal_jargon",
	"docs_comparison",
	"scribe_ai",
	"customer_analytics",
	"forecast_agent",
	"security_checks_cloud_apps",
	"db_gpt",
	"chart_generator",
	"legal_jargon",
	"docs_comparison",
	"flowchart",
	"lease_agreement",
	"document_generator",
	"summarisation_agent",
	"groq_summarisation_agent",
	"chart_interpreter",
	"floor_generator",
	"document_process",
	"contract_intelligence",
	"meeting_scheduling",
	"text_to_speech",
	"speech_to_text",
	"project_management",
	"casestudy",
	"generate_invoice",
	"market_intelligence",
	"customer_service",
	"energy",
	"employee_onboarding",
	"procore_automation",
	"trademark_agent",
	"water_billing_usage",
] )

export type FeatureType = z.infer<typeof featureSchema>

export const authenticatedRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => rootRoute,
	path: "/",
	beforeLoad: async ( { context, location } ) => {
		const auth = getPersistedAuth()
		if ( !auth ) {
			clearAuth()
			throw redirect( {
				to: "/user/login",
				search: {
					redirect: window.location.href,
				},
			} )
		}

		const me = await context.queryClient
			.ensureQueryData( {
				queryKey: [ "me" ],
				queryFn: () => fetchMe( context.client ),
			} )
			.then( ( data ) => data.message )

		document.title = `${me.organisation_name} | Gen AI`

		const fav = document.getElementById( "favicon" ) as HTMLLinkElement
		if ( fav ) {
			fav.href = me.organisation_logo_url
		}

		const pathChunks = location.pathname.split( "/" ).filter( Boolean )
		const isIndex = pathChunks.length === 0
		const parsedFeat = featureSchema.safeParse( pathChunks?.at( 0 ) )
		const isFeature = parsedFeat.success

		const feat = parsedFeat.success ? parsedFeat.data : "chats"

		if ( isFeature ) {
			const featureFlag = config.feature_flags[ feat ] && me.enabled_features[ feat ]

			if ( !featureFlag && !import.meta.env.DEV ) {
				throw redirect( {
					to: authenticatedRoute.id,
				} )
			}
		}

		if ( !context.pusher.user.signin_requested ) {
			context.pusher.signin()

			const channels = await new Promise<Channel[]>( ( resolve ) => {
				resolve( [
					context.pusher.subscribe( `private-org-${me.organisation}` ),
					context.pusher.subscribe( `private-user-${me.id}` ),
				] )
			} )

			channels.map( ( channel ) => {
				channel.bind_global( ( _: string, data: PusherPayload ) => {
					if ( data?.toast ) {
						toast( data.toast )
					}
				} )
			} )
		}

		return {
			me,
			feat,
			isIndex,
			isFeature,
			title: "",
		}
	},
	component: lazyRouteComponent( () => import( "@/features/feature/Catalogue" ), "Catalogue" ),
} )

export const chatsRouteSearchSchema = z.object( {
	session_id: z.string().optional(),
	new_session: z.string().optional(),
	multi_agent: z.string().optional(),
} )

export const chatsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "chats",
	validateSearch: chatsRouteSearchSchema,
	component: lazyRouteComponent( () => import( "@/features/chats/Chats" ) ),
	beforeLoad: ( { context } ) => {
		const chatbotChannel = context.pusher.subscribe( `private-user-chatbot-${context.me.id}` )

		return {
			chatbotChannel,
		}
	},
} )

export const compareMySiteSearchSchema = z.object( {
	session_id: z.string().optional(),
} )

export const compareMySiteRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "compare_my_site",
	validateSearch: compareMySiteSearchSchema,
	component: lazyRouteComponent( () => import( "@/features/compare-my-site/Chats" ) ),
	beforeLoad: ( { context } ) => {
		const chatbotChannel = context.pusher.subscribe( `private-user-chatbot-${context.me.id}` )

		return {
			chatbotChannel,
		}
	},
} )

export const emailsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "emails",
	beforeLoad: ( { context } ) => {
		const { feat, me } = context

		if ( feat === "emails" && me.login_type !== "google" ) {
			throw redirect( {
				to: "/",
			} )
		}
	},
	component: lazyRouteComponent( () => import( "@/features/emails/Emails" ) ),
} )

export const marketingRouteSearchSchema = z.object( {
	isSaved: z.boolean().optional(),
	tab: z.enum( [ "generate", "drafts", "integrations", "published", "workflow" ] ).catch( "generate" ),
	session: z.number().optional(),
} )

export const marketingRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "marketing",
	component: lazyRouteComponent( () => import( "@/features/marketing/Marketing" ) ),
	validateSearch: marketingRouteSearchSchema,
} )

export const salesPitchGeneratorSearchSchema = z.object( {
	tab: z.enum( [ "generate", "documents" ] ).catch( "generate" ),
} )

export const salesPitchGeneratorRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "sales_pitch_generator",
	component: lazyRouteComponent(
		() => import( "@/features/sales-pitch-generation/SalesPitchGenerator" ),
	),
	validateSearch: salesPitchGeneratorSearchSchema,
} )

export const scribeAiRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "scribe_ai",
	component: lazyRouteComponent( () => import( "@/features/scribe-ai/ScribeAI" ) ),
} )

export const forecastAgentRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "forecast_agent",
	component: lazyRouteComponent( () => import( "@/features/forecast-agent/ForecastAgent" ) ),
} )

export const securityChecksCloudAppsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "security_checks_cloud_apps",
	component: lazyRouteComponent(
		() => import( "@/features/security-checks-cloud-apps/SecurityChecksCloudApps" ),
	),
} )

export const dbGptRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "db_gpt",
	component: lazyRouteComponent( () => import( "@/features/db-gpt/DbGpt" ) ),
} )

export const chartGeneratorRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "chart_generator",
	component: lazyRouteComponent( () => import( "@/features/chart-generator/ChartGenerator" ) ),
} )

export const customerAnalyticsSearchSchema = z.object( {
	tab: z.enum( [ "cohorts", "campaigns" ] ).catch( "cohorts" ),
} )

export const customerAnalyticsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "customer_analytics",
	component: lazyRouteComponent( () => import( "@/features/customer-analytics/CustomerAnalytics" ) ),
	validateSearch: customerAnalyticsSearchSchema,
} )

export const legalJargonRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "legal_jargon",
	component: lazyRouteComponent( () => import( "@/features/legal-jargon/LegalJargon" ) ),
} )

export const docsComparisonRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "docs_comparison",
	component: lazyRouteComponent( () => import( "@/features/document-comparison/DocsComparison" ) ),
} )

export const flowchartRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "flowchart",
	component: lazyRouteComponent( () => import( "@/features/flowchart/Flowchart" ) ),
} )

export const leaseAgreementRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "lease_agreement",
	component: lazyRouteComponent( () => import( "@/features/lease-agreement/LeaseAgreement" ) ),
} )

const documentGeneratorSearchSchema = z.object( {
	form_input: z.string().optional(),
} )

export const documentGeneratorRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "document_generator",
	component: lazyRouteComponent( () => import( "@/features/document-generator/DocumentGenerator" ) ),
	validateSearch: documentGeneratorSearchSchema,
} )

export const summarisationAgentRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "summarisation_agent",
	component: lazyRouteComponent(
		() => import( "@/features/summarisation-agent/SummarisationAgent" ),
	),
} )

export const groqSummarisationAgentRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "groq_summarisation_agent",
	component: lazyRouteComponent(
		() => import( "@/features/groq-summarisation-agent/GroqSummarisationAgent" ),
	),
} )

export const chartInterpreterRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "chart_interpreter",
	component: lazyRouteComponent( () => import( "@/features/chart-interpreter/ChartInterpreter" ) ),
} )

export const floorGeneratorRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "floor_generator",
	component: lazyRouteComponent( () => import( "@/features/floor-generator/FloorGenerator" ) ),
} )

export const documentProcessRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "document_process",
	component: lazyRouteComponent( () => import( "@/features/document-processing/DocumentProcess" ) ),
} )

export const contractIntelligenceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "contract_intelligence",
	component: lazyRouteComponent(
		() => import( "@/features/contract_intelligence/ContractIntelligence" ),
	),
} )

export const meetingSchedulingRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "meeting_scheduling",
	component: lazyRouteComponent( () => import( "@/features/meeting-scheduling/MeetingScheduling" ) ),
} )

export const textToSpeechRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "text_to_speech",
	component: lazyRouteComponent( () => import( "@/features/text-to-speech/TextToSpeech" ) ),
} )

export const speechToTextRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "speech_to_text",
	component: lazyRouteComponent( () => import( "@/features/speech-to-text/SpeechToText" ) ),
} )

export const projectManagementRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "project_management",
	component: lazyRouteComponent( () => import( "@/features/project-management/ProjectManagement" ) ),
} )

export const casestudyRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "casestudy",
	component: lazyRouteComponent( () => import( "@/features/case-study-gpt/CaseStudy" ) ),
} )

export const generateInvoiceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "generate_invoice",
	component: lazyRouteComponent( () => import( "@/features/invoice_generator/InvoiceGenerator" ) ),
} )

export const marketIntelligenceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "market_intelligence",
	component: lazyRouteComponent(
		() => import( "@/features/market_intelligence/MarketIntelligence" ),
	),
} )

export const customerServiceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "customer_service",
	component: lazyRouteComponent( () => import( "@/features/customer_service/CustomerService" ) ),
} )
export const energyManagementRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "energy",
	component: lazyRouteComponent( () => import( "@/features/energy_management/EnergyManagement" ) ),
} )
export const employeeOnboarding = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "employee_onboarding",
	component: lazyRouteComponent(
		() => import( "@/features/employee_onboarding/EmployeeOnboardingThreads" ),
	),
	beforeLoad: ( { context: { client } } ) => {
		return {
			getAvailableRoles: {
				queryKey: [ "available_roles" ],
				queryFn: () => getRolesList( client ),
			},
		}
	},
} )

export const procoreAutomationSearchSchema = z.object( {
	activeTab: z.enum( [ BULK_VENDOR, INDIVIDUAL_VENDOR, VENDORS_MAPPING ] ).catch( BULK_VENDOR ),
} )

export const procoreAutomationRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "procore_automation",
	component: lazyRouteComponent( () => import( "@/features/procore_automation/Dashboard" ) ),
	validateSearch: procoreAutomationSearchSchema,
} )

export const trademarkAgentRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "trademark_agent",
	component: lazyRouteComponent(
		() => import( "@/features/trademark-agent/TrademarkAgent" ),
		"TrademarkAgent",
	),
} )

export const trademarkAgentApplicationRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => trademarkAgentRoute,
	path: "applications",
	component: lazyRouteComponent(
		() => import( "@/features/trademark-agent/TrademarkApplications" ),
		"TrademarkApplications",
	),
	beforeLoad: ( { location } ) => {
		const fragments = location.pathname.split( "/" )

		return {
			isCreate: fragments.includes( "create" ),
		}
	},
} )

export const trademarkAgentCreateApplicationRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => trademarkAgentApplicationRoute,
	path: "create",
	component: lazyRouteComponent(
		() => import( "@/features/trademark-agent/CreateApplication" ),
		"CreateApplication",
	),
} )
