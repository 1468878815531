import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const deleteChatSessions = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/user/sessions`, {
			headers,
		} )
		.json<DeleteChatSessionsOkResponse>()
}

export type DeleteChatSessionsOkResponse = number
