import { queryClient } from "@/providers/QueryProvider"

export type PersistedAuth = {
	token: string
}

export const persistAuth = ( token: string ) => {
	localStorage.setItem( AUTH_KEY, token )
}

export const clearAuth = ( reload?: boolean ) => {
	localStorage.removeItem( AUTH_KEY )
	queryClient.invalidateQueries( { queryKey: [ "me" ] } )

	if ( reload ) {
		window.location.reload()
	}
}

export const getPersistedAuth = () => {
	const tokens = localStorage.getItem( AUTH_KEY )

	if ( tokens ) {
		return tokens
	}

	return null
}

export const createAuthHeader = ( headers = new Headers() ) => {
	const token = getPersistedAuth()
	headers.append( "Authorization", `Token ${token}` )

	return headers
}

export const AUTH_KEY = "AUTH"
