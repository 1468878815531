import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"
import { CreateGuardrailResponse } from "./create-guardrail"

export const deleteGuardrail = ( ky: typeof Ky, params: DeleteGuardrailParams ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/v1/gaurdrail/${params.guardrail_id}`, { headers } )
		.json<CreateGuardrailResponse>()
}

export type DeleteGuardrailParams = {
	guardrail_id: string
}
