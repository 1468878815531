import { z } from "zod"
import { c } from "./contract-instance"
import { AttachFileSchema, FileSchema, MimeTypeEnumSchema } from "./files"

export const ConsumptionUnitSchema = z.enum( [ "kgal", "m3", "L", "thm", "gal" ] )

export const ProviderSchema = z
	.object( {
		id: z.number(),
		name: z.string().min( 1 ).max( 64 ),
		supported_formats: MimeTypeEnumSchema.array(),
		created_on: z.string().datetime(),
		updated_on: z.string().datetime(),
		image: FileSchema.pick( { url: true } ).optional(),
		sample_files: FileSchema.array(),
		consumption_units: ConsumptionUnitSchema,
	} )
	.strict()

export const ListProvidersOkResponse = z.object( {
	count: z.number(),
	next: z.string().nullable(),
	previous: z.string().nullable(),
	results: ProviderSchema.pick( {
		id: true,
		name: true,
		supported_formats: true,
	} ).array(),
} )

export const GetProviderOkSchema = z.object( {
	data: ProviderSchema.pick( {
		id: true,
		name: true,
		supported_formats: true,
		created_on: true,
		updated_on: true,
		image: true,
		sample_files: true,
		consumption_units: true,
	} ),
} )

export const CreateProviderBodySchema = ProviderSchema.pick( {
	name: true,
	supported_formats: true,
	created_on: true,
	updated_on: true,
	consumption_units: true,
} ).extend( {
	image: AttachFileSchema.optional(),
	sample_files: AttachFileSchema.array(),
} )

export const MappedTypeEnumSchema = z.enum( [
	"text",
	"integer",
	"double",
	"decimal",
	"timestamp",
	"date",
	"boolean",
] )

export const DataMappingSchema = z.object( {
	id: z.string().uuid(),
	from: z.string(),
	to: z.string(),
	description: z.string().optional(),
	settings: z.object( {} ).passthrough(),
	is_active: z.boolean(),
	example: z.string().optional(),
	type: MappedTypeEnumSchema,
	nullable: z.boolean(),
} )

export const CreateDataMappingBodySchema = DataMappingSchema.pick( {
	from: true,
	to: true,
	is_active: true,
	type: true,
	nullable: true,
} ).array()

export const providers = c.router(
	{
		getProvider: {
			method: "GET",
			path: "/:provider_id/",
			summary: "Get a Provider by id",
			pathParams: z.object( {
				provider_id: z.string().uuid(),
			} ),
			responses: {
				200: GetProviderOkSchema,
			},
		},

		listProviders: {
			method: "GET",
			path: "/",
			summary: "List Providers",
			responses: {
				200: ListProvidersOkResponse,
			},
		},
	},
	{
		pathPrefix: "/invoice/providers",
	},
)
