import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router"
import { initTsrReactQuery } from "@ts-rest/react-query/v5"
import { z } from "zod"
import config from "../config"
import { contract } from "../features/performance-services/contracts"
import { getPersistedAuth } from "../utils/auth"
import { authenticatedRoute } from "./authenticated-route"

export const performanceServiceDash = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "water_billing_usage",
	beforeLoad: ( context ) => {
		const tsr = initTsrReactQuery( contract, {
			baseUrl: config.psi_api,
			baseHeaders: {
				Authorization: `Token ${getPersistedAuth()}`,
			},
			validateResponse: true,
		} )
		const pathChunks = context.location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 1

		if ( isIndex ) {
			throw redirect( { to: listInvoicesRoute.id, search: { page: 1 } } )
		}

		return {
			title: "Project",
			isIndex,
			tsr,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/Layout" ),
		"Layout",
	),
} )

export const listInvoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "invoices",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 2
		return {
			title: "Invoice Extraction",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListInvoices" ),
		"ListInvoices",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
	} ),
} )

export const listDraftInvoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "draft-invoices",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 2
		return {
			title: "Draft Invoices",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListDraftInvoices" ),
		"ListDraftInvoices",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
	} ),
} )

export const createInvoiceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => listInvoicesRoute,
	path: "create_invoice",
	beforeLoad: () => {
		return {
			title: "Add New Invoice",
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/CreateInvoice" ),
		"CreateInvoice",
	),
} )

export const invoiceSettingsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => listInvoicesRoute,
	path: "$id/invoice_settings",
	params: {
		parse: ( params ) => z.object( { id: z.coerce.number() } ).parse( params ),
		stringify: ( params ) => z.object( { id: z.coerce.string() } ).parse( params ),
	},
	beforeLoad: () => {
		return {
			title: "Extracted Data Preview",
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/InvoiceSettings" ),
		"InvoiceSettings",
	),
} )

export const validationQueueRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "validation_queue",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 2
		return {
			title: "Validation Queue",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/validation/ValidationQueue" ),
		"ValidationQueue",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
	} ),
} )
export const invoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "invoices",
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListInvoices" ),
		"ListInvoices",
	),
} )

export const reviewDataRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => validationQueueRoute,
	path: "$id/review_data",
	params: {
		parse: ( params ) => z.object( { id: z.coerce.number() } ).parse( params ),
		stringify: ( params ) => z.object( { id: z.coerce.string() } ).parse( params ),
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/validation/ReviewData/ReviewData" ),
		"ReviewData",
	),
} )

export const masterDataRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "master_data",
	beforeLoad: ( { location, context } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 2
		const mergeChannel = context.pusher.subscribe( `psi-notif-${context.me.id}` )
		return {
			title: "Master Data",
			isIndex,
			mergeChannel,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/master-data/MasterData" ),
		"MasterData",
	),
} )

export const executiveReportRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "executive_report",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 2
		return {
			title: "Executive Report",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/master-data/ExecutiveReport" ),
		"ExecutiveReport",
	),
} )
