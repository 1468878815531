import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const createTeam = ( ky: typeof Ky, params: CreateTeamParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/user/v1/teams`, { json: params, headers } )
		.json<CreateTeamOkResponse>()
}

export type CreateTeamParams = {
	team_name: string
	team_members: number[]
}

export type CreateTeamOkResponse = unknown
