import { QueryClient, QueryClientProvider, keepPreviousData } from "@tanstack/react-query"
import React from "react"

export const queryClient = new QueryClient( {
	defaultOptions: {
		queries: {
			placeholderData: keepPreviousData,
			refetchOnWindowFocus: import.meta.env.PROD,
			staleTime: ( 1 / 2 ) * 60 * 1000,
			refetchOnMount: true,
			retry: false,
		},
	},
} )

export type QueryClientType = typeof queryClient

export const QueryProvider: React.FC<React.PropsWithChildren> = ( { children } ) => {
	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
