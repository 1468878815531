import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const listGuardrails = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky.get( `${config.api}/v1/gaurdrail`, { headers } ).json<ListGuardrailsResponse>()
}

export interface GuardrailDetail {
	id: string
	owner_id: number
	owner_email: string
	guardrail_name: string
	llm: string
	topic_filtering: {
		active: boolean
		accepted_topic: string[]
		denied_topic: string[]
	}
	content_filtering: {
		active: boolean
		prompt: {
			profanity: string
			identity_attacks: string
			obscenity: string
			violence: string
		}
		response: {
			profanity: string
			identity_attacks: string
			obscenity: string
			violence: string
		}
	}
	pii_filtering: {
		active: boolean
		prompt: {
			pii_prompts_status: string
		}
		response: {
			pii_response_status: string
		}
	}
	language_filtering: {
		active: boolean
		prompt_oriented: string[]
		response_oriented: string[]
	}
	query_violation_mesg: string
	response_violation_mesg: string
}

interface ListGuardrailsResponse {
	details: {
		message: string
		data: GuardrailDetail[]
	}
}
