import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const deleteCollection = ( ky: typeof Ky, { collection_id }: DeleteCollectionParams ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/v1/collections/${collection_id}`, { headers } )
		.json<DeleteCollectionOkResponse>()
}

export type DeleteCollectionParams = {
	collection_id: number
}

export type DeleteCollectionOkResponse = unknown
