import Ky from "ky"
import config from "@/config"

export const login = ( ky: typeof Ky, params: LoginParams ) => {
	return ky.post( `${config.api}/user/login`, { json: params } ).json<LoginOkResponse>()
}

export type LoginParams = {
	reg_email: string
	password: string
}

export type LoginOkResponse = {
	token: string
}

export type LoginUnauthenticatedResponse = {
	error: string
}
