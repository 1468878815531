import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const listCollections = (
	ky: typeof Ky,
	params: { page_no?: number; page_size?: number } = { page_no: 1, page_size: 150 },
) => {
	const headers = createAuthHeader()
	return ky
		.get( `${config.api}/v1/collections`, { headers, searchParams: params } )
		.json<ListCollectionsOkResponse>()
}

export type ListCollectionsOkResponse = {
	details: {
		message: "list of collection"
		data: Collection[]
		total_pages: number
		total_records: number
	}
}

export type Collection = {
	collection_id: number
	scheduling: string
	owner_id: number
	owner_email: string
	collection_name: string
	updated_at: string
	source: Sources
}

export type Sources = GoogleDriveConnection &
	HtmlPageConnection &
	FileConnection &
	PostgresConnection &
	GraphConn

export type GoogleDriveConnection = {
	google_drive_conn?: GoogleDriveSource[]
}

export type GoogleDriveSource = {
	folder_id: string
	id: number
	status: SourceStatus
	error_message: null | string
	last_updated: undefined | string
}

export type HtmlPageConnection = {
	html_page_conn?: HtmlPageSource[]
}

export type PostgresConnection = {
	postgres_conn?: PostgresSource[]
}

export type GraphConn = {
	graph_conn?: GraphSource[]
}

export type HtmlPageSource = {
	link: string
	id: number
	status: SourceStatus
	error_message: null | string
	last_updated: null | string
}

export type FileSource = {
	file_name: string
	id: number
	status: SourceStatus
	last_updated: null | string
}

export type PostgresSource = {
	id: number
	status: SourceStatus
	error_message: null | string
	connection_params: {
		port: number
		db_name: string
		user: string
		password: string
		host: string
	}
	query: string
	metadata_select_col?: string
	last_updated: undefined | string
}

export type GraphSource = {
	id: number
	status: SourceStatus
	error_message: null | string
	connection_data: {
		port: number
		database: string
		host: string
		username: string
		password: string
	}
}

export type FileConnection = {
	file_conn?: FileSource[]
}

export type SourceStatus = "SUCCESS" | "FAILED" | "uploading..."
