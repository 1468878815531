import { z } from "zod"
import { c } from "./contract-instance"

export const MimeTypeEnumSchema = z.enum( [
	"pdf",
	// "text/plain",
	// "application/vnd.ms-excel",
	// "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	// "application/pdf",
	"csv",
	// "image/png",
	// "image/jpeg",
] )

export const FileSchema = z.object( {
	url: z.string().url(),
	original_name: z.string(),
	mimetype: MimeTypeEnumSchema,
	status: z.enum( [ "uploading", "uploaded", "failed" ] ),
	size: z.string(),
} )

export const AttachFileSchema = FileSchema.pick( { url: true } )

export const files = c.router(
	{
		upload_file: {
			method: "POST",
			path: "",
			contentType: "multipart/form-data",
			body: c.type<{ thumbnail: File }>(),
			responses: {
				201: FileSchema,
			},
		},
	},
	{
		pathPrefix: "/files",
	},
)
