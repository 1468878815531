import { cva as cvaOrig } from "class-variance-authority"
import React from "react"
import { cn } from "./cn"

type CvaParams<T> = Parameters<typeof cvaOrig<T>>
type ClassValue<T> = CvaParams<T>[0]
type ClassConfig<T> = CvaParams<T>[1]
type VariantKeys<T> = ( keyof NonNullable<ClassConfig<T>> )[]
type VariantProps<Component extends ( ...args: any ) => any> = Omit<
	NonNullable<Parameters<Component>[0]>,
	"class" | "className"
>

type CvaFn<T> = ReturnType<typeof cvaOrig<T>> & {
	variants: VariantKeys<T>
}

export const cva = <T>( base?: ClassValue<T>, config?: ClassConfig<T> ) => {
	const fn = cvaOrig( base, config ) as CvaFn<T>

	fn.variants = Object.keys( config?.variants ?? {} ) as unknown as VariantKeys<T>

	return fn
}

export const styled = <TElement extends React.ElementType, TFn extends CvaFn<unknown>>(
	element: TElement,
	cvaFn: TFn | string,
	forward: ( keyof React.ComponentPropsWithoutRef<TElement> | keyof VariantProps<TFn> )[] = [],
	defaults?: Partial<React.ComponentPropsWithoutRef<TElement>> | null,
) => {
	const Comp = React.forwardRef<
		React.ElementRef<TElement>,
		React.ComponentPropsWithoutRef<TElement> & VariantProps<TFn>
	>( ( props, ref ) => {
		const regularProps: Record<string, unknown> = {}
		const variantProps: Record<string, unknown> = {}
		let cvaCls = ""

		if ( typeof cvaFn === "function" ) {
			for ( const propKey in props ) {
				if ( cvaFn.variants.includes( propKey ) ) {
					variantProps[ propKey ] = props[ propKey ]

					if ( forward.includes( propKey ) ) {
						regularProps[ propKey ] = props[ propKey ]
					}
				} else {
					regularProps[ propKey ] = props[ propKey ]
				}
			}

			cvaCls = cvaFn( variantProps )
		} else {
			cvaCls = cvaFn
		}

		regularProps.ref = ref

		return React.createElement( element, {
			...regularProps,
			className: cn( cvaCls, ( props as any ).className ),
		} )
	} )

	if ( defaults ) {
		Comp.defaultProps = defaults
	}

	return Comp
}
