import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"
import {
	GoogleDriveConnParams,
	HtmlPageConnParams,
	PostgresConnParams,
	FileConnParams,
} from "./create-collection"

export const updateCollection = (
	ky: typeof Ky,
	{ collection_id, source, scheduling }: UpdateCollectionParams,
) => {
	const headers = createAuthHeader()

	return ky
		.patch( `${config.api}/v1/collections/${collection_id}`, {
			json: { source, scheduling },
			headers,
		} )
		.json<UpdateCollectionOkResponse>()
}

export type UpdateCollectionParams = {
	collection_id: number
	scheduling: string
	source: GoogleDriveConnParams & HtmlPageConnParams & PostgresConnParams & FileConnParams
}

export type UpdateCollectionOkResponse = {
	details: {
		message: string
		data: {
			collection_id: number
			collection_name: string
			sources: unknown
		}
	}
}

export type UpdateCollectionErrorResponse = {
	validation_errors: {
		"source.google_drive_conn.folder_id"?: string
	}
}
