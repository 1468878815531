import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const createCollection = ( ky: typeof Ky, params: CreateCollectionParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/v1/collections/`, { json: params, headers } )
		.json<CreateCollectionOkResponse>()
}

export type CreateCollectionParams = {
	collection_name: string
	scheduling: string
	source: GoogleDriveConnParams &
		HtmlPageConnParams &
		PostgresConnParams &
		FileConnParams &
		GraphConnParams
}

export type GoogleDriveConnParams = {
	google_drive_conn?: {
		folder_id: string
	}
}

export type HtmlPageConnParams = {
	html_page_conn?: {
		links: {
			link: string
			fetch_child_links: boolean
		}[]
	}
}

export type FileConnParams = {
	file_conn?: {
		paths: string[]
	}
}

export type PostgresConnParams = {
	postgres_conn?: {
		connection_params: {
			port: number
			db_name: string
			user: string
			password: string
			host: string
		}

		query: string
		metadata_select_col: string
	}
}

export type GraphConnParams = {
	graph_conn?: {
		connection_data: {
			port: number
			database: string
			host: string
			username: string
			password: string
		}
	}
}

export type CreateCollectionValidationErrorResponse = {
	validation_errors: {
		collection_name?: string
		"source.google_drive_conn.folder_id"?: string
		"source.postgres_conn.connection_params"?: string
		"source.postgres_conn.metadata_select_col"?: string
		"source.graph_conn.connection_data"?: string
	}
}

export type CreateCollectionOkResponse = unknown

export type CreateCollectionUsageLimitErrorResponse = {
	details: {
		message: "Your organisation token limit has exhausted, please contact Attri admin."
		data: null
	}
}
