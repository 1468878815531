import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const createGuardrail = ( ky: typeof Ky, params: GuardrailParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/v1/gaurdrail/`, { json: params, headers } )
		.json<CreateGuardrailResponse>()
}

export interface GuardrailParams {
	guardrail_name: string
	topic_filtering: {
		active: boolean
		accepted_topic: string[]
		denied_topic: string[]
	}
	content_filtering: {
		active: boolean
		prompt: {
			profanity: ContentFilterKey
			identity_attacks: ContentFilterKey
			obscenity: ContentFilterKey
			violence: ContentFilterKey
		}
		response: {
			profanity: ContentFilterKey
			identity_attacks: ContentFilterKey
			obscenity: ContentFilterKey
			violence: ContentFilterKey
		}
	}
	pii_filtering: {
		active: boolean
		prompt: {
			pii_prompts_status: string
		}
		response: {
			pii_response_status: string
		}
	}
	language_filtering: {
		active: boolean
		prompt_oriented: string[]
	}
	query_violation_mesg: string
	response_violation_mesg: string
}

export type CreateGuardrailResponse = {
	details: {
		id: string
		message: string
	}
}

type ContentFilterKey = "low" | "moderate" | "high"
