import config from "@/config"
import { loginRoute } from "@/routes/non-authenticated-route"
import { Button, Card, Flex, Heading, Separator, Text } from "@radix-ui/themes"
import { Link, useSearch } from "@tanstack/react-router"
import { GoogleLogin } from "./GoogleLogin"

export const Login = () => {
	const search = useSearch( { from: loginRoute.id } )

	return (
		<Flex
			className="h-full w-full bg-cover bg-right bg-no-repeat"
			justify="center"
			align="center"
			style={{ background: "url(/assets/imgs/bg-abstract.png)" }}
		>
			<Card size="4" className="w-[500px]">
				<Flex gap="5" direction="column">
					<Heading size="5" weight="medium">
						Sign Up
					</Heading>
					<Separator size="4" />
					{typeof search.error === "string" && (
						<Text weight="medium" size="1" color="crimson" className="text-center">
							{search.error}
						</Text>
					)}
					{config.feature_flags[ "enabled_google_oauth" ] ? <GoogleLogin /> : <></>}
					<Button size="3" variant="soft" radius="large" asChild>
						<Link to="/user/email-login">Login with Email...</Link>
					</Button>

					<Flex mt="2" className="h-full justify-center" gap="3">
						<Text size="1" color="indigo" asChild>
							<a href="https://attri.ai/terms-of-service" target="_blank">
								Terms of Services
							</a>
						</Text>
						<Text size="1" color="indigo" asChild>
							<a href="https://attri.ai/privacy-policy-new" target="_blank">
								Privacy Policy
							</a>
						</Text>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	)
}
