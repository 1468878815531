import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const getTopicsList = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky.get( `${config.api}/v1/gaurdrail/topics`, { headers } ).json<TopicsResponse>()
}

export interface Topic {
	label: string
	value: string
}

export interface TopicList {
	message: string
	data: Topic[]
}

export interface TopicsResponse {
	details: TopicList
}
