import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const uploadFile = ( ky: typeof Ky, params: UploadFileParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/v1/file`, { body: params, headers, timeout: 30 * 1000 } )
		.json<UploadFileOkResponse>()
}

export type UploadFileParams = FormData

export type UploadFileOkResponse = {
	details: string
}

export type UploadFileSizeException = {
	details: string
}
