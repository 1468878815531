import { GuardrailParams, createGuardrail } from "@/api/guardrails/create-guardrail"
import { DeleteGuardrailParams, deleteGuardrail } from "@/api/guardrails/delete-guardrail"
import { listGuardrails } from "@/api/guardrails/list-guardrails"
import { getTopicsList } from "@/api/guardrails/topicsList"
import { UpdateGuardrailParams, updateGuardrail } from "@/api/guardrails/update-guardrail"
import { AddTeamCollectionsParams, addTeamCollections } from "@/api/settings/add-team-collections"
import { AddTeamMembersParams, addTeamMembers } from "@/api/settings/add-team-members"
import { CreateCollectionParams, createCollection } from "@/api/settings/create-collection"
import { CreateTeamParams, createTeam } from "@/api/settings/create-team"
import { deleteChatSessions } from "@/api/settings/delete-chat-sessions"
import { DeleteCollectionParams, deleteCollection } from "@/api/settings/delete-collection"
import { DeleteFileParams, deleteFile } from "@/api/settings/delete-file"
import { DeleteSourceParams, deleteSource } from "@/api/settings/delete-source"
import { DeleteTeamParams, deleteTeam } from "@/api/settings/delete-team"
import { getTokenInfo } from "@/api/settings/get-token-info"
import { getSchedulingOptions } from "@/api/settings/get_scheduling_options"
import { listCollections } from "@/api/settings/list-collections"
import { listTeamResources } from "@/api/settings/list-team-resources"
import { listTeams } from "@/api/settings/list-teams"
import { RefetchGoogleDriveParams, refetchGoogleDrive } from "@/api/settings/refetch-google-drive"
import {
	RemoveTeamCollectionsParams,
	removeTeamCollections,
} from "@/api/settings/remove-team-collections"
import { RemoveTeamMembersParams, removeTeamMembers } from "@/api/settings/remove-team-members"
import { UpdateCollectionParams, updateCollection } from "@/api/settings/update-collection"
import { UpdateTeamParams, updateTeam } from "@/api/settings/update-team"
import { UploadFileParams, uploadFile } from "@/api/settings/upload-file"
import { QueryOptions } from "@tanstack/react-query"
import { createRoute, lazyRouteComponent } from "@tanstack/react-router"
import { HTTPError } from "ky"
import { z } from "zod"
import { listModelProviders } from "../api/settings/list-model-providers"
import { toast } from "../components/Toast/use-toast"
import { authenticatedRoute } from "./authenticated-route"

export const settingsRoute = createRoute( {
	getParentRoute: () => authenticatedRoute,
	path: "/settings",
	component: lazyRouteComponent( () => import( "@/features/settings/Settings" ), "Settings" ),
	validateSearch: z.object( {
		from: z.string().optional(),
	} ),
	beforeLoad() {
		return {
			pageSize: 25,
		}
	},
} )

export const teamManagementRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "team-management",
	beforeLoad: ( { context: { client, queryClient } } ) => {
		return {
			listTeamResourcesQueryOptions: {
				queryKey: [ "teams", "resources" ],
				queryFn: () => listTeamResources( client ),
			},
			createTeamMutationOptions: {
				mutationFn: ( params: CreateTeamParams ) => createTeam( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			addTeamMembersMutationOptions: {
				mutationFn: ( params: AddTeamMembersParams ) => addTeamMembers( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			removeTeamMembersMutationOptions: {
				mutationFn: ( params: RemoveTeamMembersParams ) => removeTeamMembers( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			addTeamCollectionsMutationOptions: {
				mutationFn: ( params: AddTeamCollectionsParams ) =>
					addTeamCollections( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			removeTeamCollectionsMutationOptions: {
				mutationFn: ( params: RemoveTeamCollectionsParams ) =>
					removeTeamCollections( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			updateTeamMutationOptions: {
				mutationFn: ( params: UpdateTeamParams ) => updateTeam( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
			deleteTeamMutationOptions: {
				mutationFn: ( params: DeleteTeamParams ) => deleteTeam( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "teams" ], exact: false } )
				},
			},
		}
	},
	loader: ( { context } ) => {
		return Promise.all( [
			context.queryClient.ensureQueryData( context.listTeamResourcesQueryOptions ),
			context.queryClient.ensureQueryData( {
				queryKey: [ "teams", 1 ],
				queryFn: () =>
					listTeams( context.client, { page_no: 1, page_size: context.pageSize } ),
			} ),
		] )
	},
	component: lazyRouteComponent(
		() => import( "@/features/team-management/TeamManagement" ),
		"TeamManagement",
	),
	validateSearch: z.object( {
		page: z.number().min( 1 ).catch( 1 ),
	} ),
} )

export const dataCollectionsRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "data-collections",
	beforeLoad: ( { context: { client, queryClient } } ) => {
		return {
			getSchedulingOptions: {
				queryKey: [ "scheduling_options" ],
				queryFn: () => getSchedulingOptions( client ),
			},
			refetchGoogleDriveMutationOptions: {
				mutationFn: ( params: RefetchGoogleDriveParams ) =>
					refetchGoogleDrive( client, params ),
			},
			uploadFileMutationOptions: {
				mutationFn: ( params: UploadFileParams ) => uploadFile( client, params ),
			},
			deleteFileMutationOptions: {
				mutationFn: ( params: DeleteFileParams ) => deleteFile( client, params ),
			},
			deleteSourceMutationOptions: {
				mutationFn: ( params: DeleteSourceParams ) => deleteSource( client, params ),
			},

			createCollectionMutationOptions: {
				mutationkey: [ "create-collection" ],
				mutationFn: ( params: CreateCollectionParams ) => createCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
			},
			deleteCollectionMutationOptions: {
				mutationFn: ( params: DeleteCollectionParams ) => deleteCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
				onError: async ( err: unknown ) => {
					if ( err instanceof HTTPError ) {
						const data = ( await err.response.json() ) as { details: string }
						if ( typeof data.details === "string" ) {
							toast( {
								title: "Error while deleting Knowledge base",
								description: data.details,
								variant: "warning",
							} )
						}
					}
				},
			},
			editCollectionMutationOptions: {
				mutationkey: [ "edit-collection" ],
				mutationFn: ( params: UpdateCollectionParams ) => updateCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
			},
		}
	},
	loader: async ( { context } ) => {
		const data = await context.queryClient.ensureQueryData( {
			queryKey: [ "collections", 1 ],
			queryFn: () =>
				listCollections( context.client, { page_no: 1, page_size: context.pageSize } ),
		} )

		const loadedRecords = new Map( data.details.data.map( ( d ) => [ d.collection_id, 1 ] ) )

		return {
			loadedRecords,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/data-collection/CollectionList" ),
		"CollectionList",
	),
	validateSearch: z.object( {
		page: z.number().min( 1 ).catch( 1 ),
	} ),
	wrapInSuspense: true,
} )

export const widgetsRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "widgets",
	component: lazyRouteComponent( () => import( "@/features/settings/Widgets" ), "Widgets" ),
	validateSearch: z.object( {
		tab: z.enum( [ "create", "list", "edit" ] ).catch( "list" ),
		index: z.number().optional(),
	} ),
} )

export const inviteUsersRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "invite-users",
	beforeLoad: ( { context: { client, queryClient } } ) => {
		return {
			getCollectionsQueryOptions: {
				queryKey: [ "collections" ],
				queryFn: () => listCollections( client ),
			},
			createCollectionMutationOptions: {
				mutationkey: [ "create-collection" ],
				mutationFn: ( params: CreateCollectionParams ) => createCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
			},
			deleteCollectionMutationOptions: {
				mutationFn: ( params: DeleteCollectionParams ) => deleteCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
			},
			editCollectionMutationOptions: {
				mutationkey: [ "edit-collection" ],
				mutationFn: ( params: UpdateCollectionParams ) => updateCollection( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "collections" ], exact: false } )
				},
			},
			refetchGoogleDriveMutationOptions: {
				mutationFn: ( params: RefetchGoogleDriveParams ) =>
					refetchGoogleDrive( client, params ),
			},
			uploadFileMutationOptions: {
				mutationFn: ( params: UploadFileParams ) => uploadFile( client, params ),
			},
			deleteFileMutationOptions: {
				mutationFn: ( params: DeleteFileParams ) => deleteFile( client, params ),
			},
			deleteSourceMutationOptions: {
				mutationFn: ( params: DeleteSourceParams ) => deleteSource( client, params ),
			},
		}
	},
	loader: ( { context } ) => {
		return context.queryClient.ensureQueryData( context.getCollectionsQueryOptions )
	},
	component: lazyRouteComponent( () => import( "@/features/settings/InviteUsers" ), "InviteUsers" ),
} )

export const modelConfigurationRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "model-configuration",
	component: lazyRouteComponent(
		() => import( "@/features/settings/ModelConfiguration/ModelConfiguration" ),
		"ModelConfiguration",
	),
	beforeLoad: ( { context: { client } } ) => {
		const listModelProvidersQueryOptions = {
			queryKey: [ "model-providers" ],
			queryFn: () => listModelProviders( client ),
		} satisfies QueryOptions

		return {
			listModelProvidersQueryOptions,
		}
	},
} )

export const userSettingsRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "user-settings",
	component: lazyRouteComponent( () => import( "@/features/settings/UserSettings" ), "UserSettings" ),
	beforeLoad: ( { context: { client, queryClient } } ) => {
		return {
			removeTeamCollectionsMutationOptions: {
				mutationFn: () => deleteChatSessions( client ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "get-sessions" ] } )
				},
			},
			getTokenInfoQueryOptions: {
				queryKey: [ "token-info" ],
				queryFn: () => getTokenInfo( client ),
			},
		}
	},
	loader: ( { context } ) => {
		return context.queryClient.ensureQueryData( context.getTokenInfoQueryOptions )
	},
} )

export const guardrailsRoute = createRoute( {
	getParentRoute: () => settingsRoute,
	path: "guardrails",
	component: lazyRouteComponent( () => import( "@/features/guardrails/Guardrails" ) ),
	beforeLoad: ( { context: { client, queryClient } } ) => {
		return {
			createGuardrailOptions: {
				mutationFn: ( params: GuardrailParams ) => createGuardrail( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "guardrails" ] } )
				},
			},
			updateGuardrailOptions: {
				mutationFn: ( params: UpdateGuardrailParams ) => updateGuardrail( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "guardrails" ] } )
				},
			},
			getGuardrailOptions: {
				queryKey: [ "guardrails" ],
				queryFn: () => listGuardrails( client ),
			},
			getGuardrailTopicListOptions: {
				queryKey: [ "topic-list" ],
				queryFn: () => getTopicsList( client ),
			},
			deleteGuardrailOptions: {
				mutationFn: ( params: DeleteGuardrailParams ) => deleteGuardrail( client, params ),
				onSuccess: async () => {
					await queryClient.invalidateQueries( { queryKey: [ "guardrails" ] } )
				},
			},
		}
	},
} )
