import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const getTokenInfo = ( ky: typeof Ky ) => {
	const headers = createAuthHeader()
	return ky.get( `${config.api}/user/get-token-info`, { headers } ).json<GetTokenInfoOkResponse>()
}

export type GetTokenInfoOkResponse = {
	details: {
		token_usage: number
		token_limit: number
		vector_count: number
		vector_limit: number
	}
}
