import { toast } from "@/components/Toast/use-toast"
import { AUTH_KEY } from "@/utils/auth"
import ky from "ky"
import React from "react"

export const client = ky.create( {
	timeout: 30 * 1000,
	retry: {
		limit: 3,
		methods: [ "get", "put", "post", "patch" ],
		statusCodes: [ 408, 425, 429, 500, 502, 503, 504 ],
		backoffLimit: 3000,
	},
	hooks: {
		afterResponse: [
			async ( request, __, response ) => {
				if ( response.status === 401 && !request.url.includes( "/user/login" ) ) {
					toast( {
						title: "Signed out",
						description: "Login to continue",
					} )
					localStorage.removeItem( AUTH_KEY )
					window.location.href = `/user/login`

					return response
				}
			},
		],
	},
} )

export const ClientProvider: React.FC<ClientProviderProps> = ( { children } ) => {
	return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
}

export const useClient = () => React.useContext( ClientContext )

export type ClientProviderProps = React.PropsWithChildren

export type ClientContextValue = typeof ky

const ClientContext = React.createContext<ClientContextValue>( client )
