import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const refetchGoogleDrive = ( ky: typeof Ky, params: RefetchGoogleDriveParams ) => {
	const headers = createAuthHeader()

	return ky
		.post( `${config.api}/v1/source/google-drive/${params.source_id}/refetch`, {
			json: params,
			headers,
		} )
		.json<RefetchGoogleDriveOkResponse>()
}

export type RefetchGoogleDriveParams = {
	source_id: number
}

export type RefetchGoogleDriveOkResponse = {
	details: {
		title: string
		variant: "destructive" | "success" | "warning" | "info"
	}
}
