import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const updateTeam = ( ky: typeof Ky, { team_id, team_name }: UpdateTeamParams ) => {
	const headers = createAuthHeader()

	return ky
		.patch( `${config.api}/user/v1/teams/${team_id}`, { json: { team_name }, headers } )
		.json<UpdateTeamOkResponse>()
}

export type UpdateTeamParams = {
	team_name: string
	team_id: number
}

export type UpdateTeamOkResponse = unknown
