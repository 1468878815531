import "./DotsLoader.css"

export interface DotsLoaderProps {
	light?: boolean
}

export const DotsLoader = () => {
	return (
		<div className="loading-dots flex h-full w-full items-center justify-center gap-1">
			<div className="dot bg-accent-9"></div>
			<div className="dot bg-accent-9"></div>
			<div className="dot bg-accent-9"></div>
		</div>
	)
}
