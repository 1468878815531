import config from "@/config"
import { createAuthHeader } from "@/utils/auth"
import Ky from "ky"

export const deleteSource = ( ky: typeof Ky, params: DeleteSourceParams ) => {
	const headers = createAuthHeader()

	return ky
		.delete( `${config.api}/v1/source`, { headers, searchParams: params } )
		.json<DeleteSourceOkResponse>()
}

export type DeleteSourceParams = {
	source_type: AvailableSourceType
	source_id: number
}

export type AvailableSourceType =
	| "google_drive_conn"
	| "html_page_conn"
	| "file_conn"
	| "postgres_conn"
	| "graph_conn"

export type DeleteSourceOkResponse = unknown
