import config from "@/config"
import Ky from "ky"

export const getRolesList = ( ky: typeof Ky ) => {
	return ky.get( `${config.employee_onboarding_be_api}/roles` ).json<RolesListResponse>()
}

interface RolesListResponse {
	roles: string[]
}
